"use strict";

angular
  .module("cpformplastApp")
  .service(
    "DataManager",
    function (
      $http,
      appConfig,
      Machine,
      Subjob,
      SubjobSchedule,
      WeekSchedule
    ) {
      /*****************************************/
      /*																			 */
      /*								Categories  					 */
      /*																			 */
      /*****************************************/

      this.fetchCategories = function (showArchive) {
        return $http
          .get(appConfig.api.url + `/api/categories?showArchive=${showArchive}`)
          .then(function (response) {
            return response.data;
          });
      };

      this.fetchCategory = function (categoryId) {
        return $http
          .get(appConfig.api.url + "/api/categories/" + categoryId)
          .then(function (response) {
            return response.data;
          });
      };

      this.createCategory = function (category) {
        return $http
          .post(appConfig.api.url + "/api/categories", {
            type: category.type.toUpperCase(),
            dimensions: category.dimensions,
            details:
              category.details !== undefined
                ? category.details.toUpperCase()
                : "",
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.updateCategory = function (category) {
        return $http
          .put(appConfig.api.url + "/api/categories/" + category._id, {
            type: category.type.toUpperCase(),
            dimensions: category.dimensions,
            details:
              category.details !== undefined
                ? category.details.toUpperCase()
                : "",
            archive: category.archive,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.archiveCategory = function (categoryId, archive) {
        return $http
          .put(appConfig.api.url + "/api/categories/" + categoryId, {
            archive: archive,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.deleteCategory = function (category) {
        return $http
          .delete(appConfig.api.url + "/api/categories/" + category._id)
          .then(function (response) {
            return response.data;
          });
      };

      /*****************************************/
      /*																			 */
      /*								Items									 */
      /*																			 */
      /*****************************************/

      this.fetchItems = function (showArchive) {
        return $http
          .get(appConfig.api.url + `/api/items?showArchive=${showArchive}`)
          .then(function (response) {
            return response.data;
          });
      };

      this.fetchItem = function (itemId) {
        return $http
          .get(appConfig.api.url + "/api/items/" + itemId)
          .then(function (response) {
            return response.data;
          });
      };

      this.createItem = function (item) {
        console.log(item);
        return $http
          .post(appConfig.api.url + "/api/items", {
            mold: item.mold.toUpperCase(),
            die: item.die ? item.die.toUpperCase() : "",
            code: item.code.toUpperCase(),
            categoryId: item.category._id,
            clientId: item.client._id,
            name: item.name.toUpperCase(),
            ups: item.ups,
            speed: item.speed,
            dimensions: item.dimensions,
            quantityPerBox: item.quantityPerBox,
            quantityPerSkid: item.quantityPerSkid,
            appropriateMachinesId: item.appropriateMachinesId,
            adjustedStroke: item.adjustedStroke,
            unitPrice: item.unitPrice,
            quantityPerRow: item.quantityPerRow,
            rowCount: item.rowCount,
            technicalInfos: item.technicalInfos,
            otherInfos: item.otherInfos,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.updateItem = function (item) {
        return $http
          .put(appConfig.api.url + "/api/items/" + item._id, {
            mold: item.mold.toUpperCase(),
            die: item.die ? item.die.toUpperCase() : "",
            code: item.code.toUpperCase(),
            categoryId: item.category._id,
            clientId: item.client._id,
            name: item.name.toUpperCase(),
            archive: item.archive,
            ups: item.ups,
            speed: item.speed,
            dimensions: item.dimensions,
            quantityPerBox: item.quantityPerBox,
            quantityPerSkid: item.quantityPerSkid,
            appropriateMachinesId: item.appropriateMachinesId,
            adjustedStroke: item.adjustedStroke,
            unitPrice: item.unitPrice,
            quantityPerRow: item.quantityPerRow,
            rowCount: item.rowCount,
            technicalInfos: item.technicalInfos,
            otherInfos: item.otherInfos,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.deleteItem = function (item) {
        return $http
          .delete(appConfig.api.url + "/api/items/" + item._id)
          .then(function (response) {
            return response.data;
          });
      };

      /*****************************************/
      /*																			 */
      /*								salesmen     					 */
      /*																			 */
      /*****************************************/

      this.fetchSalesmen = function () {
        return $http
          .get(appConfig.api.url + `/api/salesmen`)
          .then(function (response) {
            return response.data;
          });
      };

      this.createSalesman = function (name) {
        return $http
          .post(appConfig.api.url + "/api/salesmen", {
            name: name,
          })
          .then(function (response) {
            return response.data;
          });
      };

      /*****************************************/
      /*																			 */
      /*								clients     					 */
      /*																			 */
      /*****************************************/

      this.fetchClients = function () {
        return $http
          .get(appConfig.api.url + `/api/clients`)
          .then(function (response) {
            return response.data;
          });
      };

      this.fetchClient = function (clientId) {
        return $http
          .get(appConfig.api.url + `/api/clients/` + clientId)
          .then(function (response) {
            return response.data;
          });
      };

      this.createClient = function (client) {
        return $http
          .post(appConfig.api.url + "/api/clients", {
            name: client.name,
            email: client.email,
            terms: client.terms,
            shippingAddress: client.shippingAddress,
            billingAddress: client.billingAddress,
            salesmanId: client.salesmanId,
            contact: client.contact,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.updateClient = function (client) {
        return $http
          .put(appConfig.api.url + "/api/clients/" + client._id, {
            name: client.name,
            email: client.email,
            terms: client.terms,
            shippingAddress: client.shippingAddress,
            billingAddress: client.billingAddress,
            salesmanId: client.salesmanId,
            contact: client.contact,
          })
          .then(function (response) {
            return response.data;
          });
      };

      /*****************************************/
      /*									   */
      /*				Places       		   */
      /*									   */
      /*****************************************/

      this.autocomplete = function (query) {
        const formatedQuery = query.replace(/\s+/g, "-").toLowerCase();
        return $http
          .get(appConfig.api.url + "/api/places/" + formatedQuery)
          .then(function (response) {
            return response.data;
          });
      };

      /*****************************************/
      /*									   */
      /*				Jobs				   */
      /*									   */
      /*****************************************/

      this.fetchJobs = function (showArchive) {
        return $http
          .get(appConfig.api.url + `/api/jobs?showArchive=${showArchive}`)
          .then(function (response) {
            return response.data;
          });
      };

      this.fetchJob = function (jobId) {
        return $http
          .get(appConfig.api.url + "/api/jobs/" + jobId)
          .then(function (response) {
            return response.data;
          });
      };

      this.createJob = function (job) {
        return $http
          .post(appConfig.api.url + "/api/jobs", {
            number: job.number.toUpperCase(),
            po: job.po.toUpperCase(),
            poDate: job.poDate,
            itemId: job.itemId,
            secondItemId: job.secondItemId,
            thirdItemId: job.thirdItemId,
            machineType: job.machineType,
            transport: job.transport,
            fab_fob: job.fab_fob,
            materialDeliveryDate: job.materialDeliveryDate,
            materialInStock: job.materialInStock,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.updateJob = function (job) {
        const jobId = job._id ? job._id : job.id;
        return $http
          .put(appConfig.api.url + "/api/jobs/" + jobId, {
            number: typeof job.number === "string" ? job.number.toUpperCase() : job.number,
            po: job.po.toUpperCase(),
            poDate: job.poDate,
            itemId: job.itemId,
            secondItemId: job.secondItemId,
            thirdItemId: job.thirdItemId,
            machineType: job.machineType,
            transport: job.transport,
            fab_fob: job.fab_fob,
            materialDeliveryDate: job.materialDeliveryDate,
            materialInStock: job.materialInStock,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.archiveJob = function (jobId, archive) {
        return $http
          .put(appConfig.api.url + "/api/jobs/" + jobId, {
            archive: archive,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.deleteJob = function (job) {
        return $http
          .delete(appConfig.api.url + "/api/jobs/" + job._id)
          .then(function (response) {
            return response.data;
          });
      };

      /*****************************************/
      /*				   					   */
      /*				Subjobs				   */
      /*									   */
      /*****************************************/

      this.createSubjob = function (jobId, subjob) {
        return $http
          .post(appConfig.api.url + "/api/jobs/" + jobId + "/subjobs", {
            index: subjob.index,
            po: subjob.po,
            quantity: subjob.quantity,
            overheadPercentage: subjob.overheadPercentage,
            date: subjob.date,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.fetchUnplannedSubjobs = function () {
        return $http
          .get(appConfig.api.url + "/api/jobs/subjobs?unplanned=true")
          .then((req) => req.data.map((o) => new Subjob(o)));
      };

      this.fetchNewSubjobsCount = function () {
        return $http
          .get(appConfig.api.url + "/api/jobs/subjobs?mode=count-new")
          .then((req) => req.data)
          .catch(() => 0);
      };

      this.setSubjobsSeen = function () {
        return $http.put(appConfig.api.url + "/api/jobs/set-seen");
      };

      this.updateSubjob = function (jobId, subjob) {
        return $http
          .put(
            appConfig.api.url + "/api/jobs/" + jobId + "/subjobs/" + subjob._id,
            {
              index: subjob.index,
              po: subjob.po,
              quantity: subjob.quantity,
              overheadPercentage: subjob.overheadPercentage,
              date: subjob.date,
              notes: subjob.notes,
            }
          )
          .then(function (response) {
            return response.data;
          });
      };

      this.deleteSubjob = function (jobId, subjob) {
        return $http
          .delete(
            appConfig.api.url + "/api/jobs/" + jobId + "/subjobs/" + subjob._id
          )
          .then(function (response) {
            return response.data;
          });
      };

      /*****************************************/
      /*									   */
      /*			Subjob Schedules		   */
      /*									   */
      /*****************************************/

      this.fetchSubjobSchedules = function (mode, machineType, itemId, origin = null) {
        var url = appConfig.api.url + "/api/jobs/schedules";

        let params = [];
        if (mode) params.push({ key: "mode", value: mode });
        if (machineType)
          params.push({ key: "machineType", value: machineType });
        if (itemId) params.push({ key: "itemId", value: itemId });

        if (origin) params.push({ key: "origin", value: origin });

        if (params.length > 0) url += "?";

        for (let i = 0; i < params.length; i++) {
          if (i > 0) url += "&";
          url += `${params[i].key}=${params[i].value}`;
        }

        let promise = $http.get(url);
        if (mode == "week") {
          return promise.then((req) =>
            req.data.map((o) => new WeekSchedule(o))
          );
        } else if (!mode || mode.indexOf("machine") > -1) {
          return promise.then((req) => req.data.map((o) => new Machine(o)));
        } else {
          return promise.then((req) =>
            req.data.map((o) => new SubjobSchedule(o))
          );
        }
      };

      this.createSubjobSchedule = function (subjob, machine, startDate) {
        startDate.setMinutes(0);

        console.log("dans create subjobs schedule");

        const url =
          appConfig.api.url +
          "/api/jobs/" +
          subjob.job.id +
          "/subjobs/" +
          subjob.id +
          "/schedules";
        return $http
          .post(url, {
            machineId: machine.id,
            quantity: subjob.remainingQuantity,
            startDate: startDate,
          })
          .then((req) => req.data.map((o) => new Machine(o)));
      };

      this.updateSubjobSchedule = function (subjobSchedule, mode) {
        subjobSchedule.startDate.setMinutes(0);

        const url =
          appConfig.api.url +
          "/api/jobs/" +
          subjobSchedule.subjob.job.id +
          "/subjobs/" +
          subjobSchedule.subjob.id +
          "/schedules/" +
          subjobSchedule.id +
          "?mode=" +
          mode;
        let promise = $http.put(url, {
          machineId: subjobSchedule.machineId,
          startDate: subjobSchedule.startDate,
          quantity: subjobSchedule.quantity,
          materialReady: subjobSchedule.materialReady,
          requiresOvertime: subjobSchedule.requiresOvertime,
          waitTimeBeforeNextSchedule: subjobSchedule.waitTimeBeforeNextSchedule,
          itemSchedule: {
            moldOut: subjobSchedule.itemSchedule.moldOut,
            sheetVerified: subjobSchedule.itemSchedule.sheetVerified,
            readyProd: subjobSchedule.itemSchedule.readyProd,
            readyProdDate: subjobSchedule.itemSchedule.readyProdDate,
            dieOrderStock: subjobSchedule.itemSchedule.dieOrderStock,
            comment: subjobSchedule.itemSchedule.comment,
            fFinition: subjobSchedule.itemSchedule.fFinition,
            alumBlockOrdered: subjobSchedule.itemSchedule.alumBlockOrdered,
            mMachine: subjobSchedule.itemSchedule.mMachine,
            dieVerified: subjobSchedule.itemSchedule.dieVerified,
            recycleMold: subjobSchedule.itemSchedule.recycleMold,
            mechanic: subjobSchedule.itemSchedule.mechanic,
            startDate: subjobSchedule.itemSchedule.startDate,
            endDate: subjobSchedule.itemSchedule.endDate,
            setupEndDate: subjobSchedule.itemSchedule.setupEndDate,
          },
        });

        if (mode === "single") {
          return promise.then((req) => new SubjobSchedule(req.data));
        } else {
          return promise.then((req) => req.data.map((o) => new Machine(o)));
        }
      };

      this.completeSubjobSchedule = function (subjobSchedule) {
        const url =
          appConfig.api.url +
          "/api/jobs/" +
          subjobSchedule.subjob.job.id +
          "/subjobs/" +
          subjobSchedule.subjob.id +
          "/schedules/" +
          subjobSchedule.id;
        return $http
          .put(url, { completed: true })
          .then((req) => req.data.map((o) => new Machine(o)));
      };

      this.deleteSubjobSchedule = function (subjobSchedule) {
        const url =
          appConfig.api.url +
          "/api/jobs/" +
          subjobSchedule.subjob.job.id +
          "/subjobs/" +
          subjobSchedule.subjob.id +
          "/schedules/" +
          subjobSchedule.id;
        return $http
          .delete(url)
          .then((req) => req.data.map((o) => new Machine(o)));
      };

      /*****************************************/
      /*																			 */
      /*							 Locations							 */
      /*																			 */
      /*****************************************/

      this.fetchLocations = function () {
        return $http
          .get(appConfig.api.url + "/api/locations")
          .then(function (response) {
            return response.data;
          });
      };

      this.fetchLocationsWithContent = function () {
        return $http
          .get(appConfig.api.url + "/api/locations?mode=inventory")
          .then(function (response) {
            return response.data;
          });
      };

      this.fetchLocation = function (locationId) {
        return $http
          .get(appConfig.api.url + "/api/locations/" + locationId)
          .then(function (response) {
            return response.data;
          });
      };

      this.createLocation = function (location) {
        return $http
          .post(appConfig.api.url + "/api/locations", {
            number: location.number,
            capacity: location.capacity,
            zone: location.zone,
            level: location.level,
            content: location.content,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.updateLocation = function (location) {
        return $http
          .put(appConfig.api.url + "/api/locations/" + location._id, {
            number: location.number,
            capacity: location.capacity,
            zone: location.zone,
            level: location.level,
            content: location.content,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.deleteLocation = function (location) {
        return $http
          .delete(appConfig.api.url + "/api/locations/" + location._id)
          .then(function (response) {
            return response.data;
          });
      };

      /*****************************************/
      /*																			 */
      /*							 	Orders   							 */
      /*																			 */
      /*****************************************/

      this.fetchOrder = function (orderId) {
        return $http
          .get(appConfig.api.url + "/api/orders/" + orderId)
          .then(function (response) {
            return response.data;
          });
      };

      this.createOrder = function (order) {
        return $http
          .post(appConfig.api.url + "/api/orders", {
            po: order.po.toUpperCase(),
            supplier: order.supplier.toUpperCase(),
            details: order.details ? order.details.toUpperCase() : "",
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.updateOrder = function (order) {
        return $http
          .put(appConfig.api.url + "/api/orders/" + order._id, {
            po: order.po.toUpperCase(),
            supplier: order.supplier.toUpperCase(),
            details: order.details ? order.details.toUpperCase() : "",
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.deleteOrder = function (order) {
        return $http
          .delete(appConfig.api.url + "/api/orders/" + order._id)
          .then(function (response) {
            return response.data;
          });
      };

      /*****************************************/
      /*																			 */
      /*							suborders   						 */
      /*																			 */
      /*****************************************/

      this.fetchActualSuborders = function () {
        return $http
          .get(appConfig.api.url + "/api/suborders?mode=actual")
          .then(function (response) {
            return response.data;
          });
      };

      this.fetchHistorySuborders = function () {
        return $http
          .get(appConfig.api.url + "/api/suborders?mode=history")
          .then(function (response) {
            return response.data;
          });
      };

      this.createSuborder = function (suborder) {
        return $http
          .post(appConfig.api.url + "/api/suborders", {
            orderId: suborder.orderId,
            jobId: suborder.jobId,
            deliveryDate: suborder.deliveryDate.getTime(),
            quantity: suborder.quantity,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.updateSuborder = function (suborder) {
        return $http
          .put(appConfig.api.url + "/api/suborders/" + suborder._id, {
            orderId: suborder.orderId,
            jobId: suborder.jobId,
            deliveryDate: suborder.deliveryDate.getTime(),
            quantity: suborder.quantity,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.completeSuborder = function (suborder) {
        return $http
          .put(appConfig.api.url + "/api/suborders/" + suborder._id, {
            placed: true,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.deleteSuborder = function (suborder) {
        return $http
          .delete(appConfig.api.url + "/api/suborders/" + suborder._id)
          .then(function (response) {
            return response.data;
          });
      };

      /*****************************************/
      /*																			 */
      /*							Deliveries   						 */
      /*																			 */
      /*****************************************/

      this.fetchActualDeliveries = function (
        searchTerm,
        sortField,
        sortDirection,
        limit,
        skip
      ) {
        const url = `/api/deliveries?mode=actual&search=${searchTerm}&sort=${sortField} ${sortDirection}&limit=${limit}&skip=${skip}`;
        return $http.get(appConfig.api.url + url).then(function (response) {
          return response.data;
        });
      };

      this.fetchHistoryDeliveries = function () {
        return $http
          .get(appConfig.api.url + "/api/deliveries?mode=history")
          .then(function (response) {
            return response.data;
          });
      };

      this.fetchDelivery = function (deliveryId) {
        return $http
          .get(appConfig.api.url + "/api/deliveries/" + deliveryId)
          .then(function (response) {
            return response.data;
          });
      };

      this.fetchLatestDelivery = function () {
        return $http
          .get(appConfig.api.url + "/api/deliveries/latest")
          .then(function (response) {
            return response.data;
          });
      };

      this.createDelivery = function (delivery) {
        var bol = delivery.bol ? delivery.bol.toUpperCase() : "";
        return $http
          .post(appConfig.api.url + "/api/deliveries", {
            shipper: delivery.shipper.toUpperCase(),
            booking: delivery.booking.toUpperCase(),
            bol: bol,
            date: delivery.date.getTime(),
            timeOfDay: delivery.timeOfDay,
            details: delivery.details ? delivery.details.toUpperCase() : "",
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.updateDelivery = function (delivery) {
        var bol = delivery.bol ? delivery.bol.toUpperCase() : "";
        return $http
          .put(appConfig.api.url + "/api/deliveries/" + delivery._id, {
            shipper: delivery.shipper.toUpperCase(),
            booking: delivery.booking.toUpperCase(),
            bol: bol,
            date: delivery.date.getTime(),
            timeOfDay: delivery.timeOfDay,
            details: delivery.details ? delivery.details.toUpperCase() : "",
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.deleteDelivery = function (delivery) {
        return $http
          .delete(appConfig.api.url + "/api/deliveries/" + delivery._id)
          .then(function (response) {
            return response.data;
          });
      };

      /*****************************************/
      /*																			 */
      /*					 	subdeliveries						 	 */
      /*																			 */
      /*****************************************/

      this.createSubdelivery = function (subdelivery) {
        return $http
          .post(appConfig.api.url + "/api/subdeliveries", {
            jobId: subdelivery.jobId,
            deliveryId: subdelivery.deliveryId,
            quantity: subdelivery.quantity,
            boxQuantity: subdelivery.boxQuantity,
            clientName: subdelivery.clientName,
            clientAddress: subdelivery.clientAddress,
            transport: subdelivery.transport,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.updateSubdelivery = function (subdelivery) {
        return $http
          .put(appConfig.api.url + "/api/subdeliveries/" + subdelivery._id, {
            jobId: subdelivery.jobId,
            deliveryId: subdelivery.deliveryId,
            quantity: subdelivery.quantity,
            boxQuantity: subdelivery.boxQuantity,
            clientName: subdelivery.clientName,
            clientAddress: subdelivery.clientAddress,
            transport: subdelivery.transport,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.deleteSubdelivery = function (subdelivery) {
        return $http
          .delete(appConfig.api.url + "/api/subdeliveries/" + subdelivery._id)
          .then(function (response) {
            return response.data;
          });
      };

      /*****************************************/
      /*																			 */
      /*							Raw materials						 */
      /*																			 */
      /*****************************************/

      this.fetchRawMaterials = function () {
        return $http
          .get(appConfig.api.url + "/api/rawMaterials")
          .then(function (response) {
            return response.data;
          });
      };

      this.fetchGroupedRawMaterials = function () {
        return $http
          .get(appConfig.api.url + "/api/rawMaterials?grouped=true")
          .then(function (response) {
            return response.data;
          });
      };

      this.fetchRawMaterial = function (rawMaterialId) {
        return $http
          .get(appConfig.api.url + "/api/rawMaterials/" + rawMaterialId)
          .then(function (response) {
            return response.data;
          });
      };

      // job optionelle et s'assurer d'avoir tout les données
      this.createRawMaterial = function (rawMaterial) {
        return $http
          .post(appConfig.api.url + "/api/rawMaterials", {
            jobId: !!rawMaterial.job ? rawMaterial.job._id : null,
            locationId: rawMaterial.locationId,
            quantity: rawMaterial.quantity,
            dimensions: rawMaterial.dimensions,
            categoryId: rawMaterial.category._id,
            suborderId: rawMaterial.suborderId,
            productionDeliveryId: rawMaterial.productionDeliveryId,
            dock: rawMaterial.dock,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.updateRawMaterial = function (rawMaterial) {
        return $http
          .put(appConfig.api.url + "/api/rawMaterials/" + rawMaterial._id, {
            jobId: !!rawMaterial.job ? rawMaterial.job._id : null,
            locationId: rawMaterial.locationId,
            quantity: rawMaterial.quantity,
            categoryId: rawMaterial.category._id,
            suborderId: rawMaterial.suborderId,
            productionDeliveryId: rawMaterial.productionDeliveryId,
            dock: rawMaterial.dock,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.deleteRawMaterial = function (rawMaterial) {
        return $http
          .delete(appConfig.api.url + "/api/rawMaterials/" + rawMaterial._id)
          .then(function (response) {
            return response.data;
          });
      };

      /*****************************************/
      /*																			 */
      /*						Finished products					 */
      /*																			 */
      /*****************************************/

      this.fetchGroupedFinishedProducts = function () {
        return $http
          .get(appConfig.api.url + "/api/finishedProducts?grouped=true")
          .then(function (response) {
            return response.data;
          });
      };

      this.createFinishedProduct = function (finishedProduct) {
        return $http
          .post(appConfig.api.url + "/api/finishedProducts", {
            jobId: finishedProduct.job._id,
            itemId: finishedProduct.itemId,
            locationId: finishedProduct.locationId,
            productionDeliveryId: finishedProduct.productionDeliveryId,
            quantity: finishedProduct.quantity,
            dock: finishedProduct.dock,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.updateFinishedProduct = function (finishedProduct) {
        return $http
          .put(
            appConfig.api.url + "/api/finishedProducts/" + finishedProduct._id,
            {
              jobId: finishedProduct.job._id,
              itemId: finishedProduct.itemId,
              locationId: finishedProduct.locationId,
              productionDeliveryId: finishedProduct.productionDeliveryId,
              quantity: finishedProduct.quantity,
              dock: finishedProduct.dock,
            }
          )
          .then(function (response) {
            return response.data;
          });
      };

      this.deleteFinishedProduct = function (finishedProduct) {
        return $http
          .delete(
            appConfig.api.url + "/api/finishedProducts/" + finishedProduct._id
          )
          .then(function (response) {
            return response.data;
          });
      };

      /*****************************************/
      /*																			 */
      /*								Stats									 */
      /*																			 */
      /*****************************************/

      this.fetchOrdersCount = function () {
        return $http
          .get(appConfig.api.url + "/api/stats/orderscount/10")
          .then(function (response) {
            return response.data;
          })
          .catch(function (err) {
            return err.data;
          });
      };

      this.fetchDeliveriesCount = function () {
        return $http
          .get(appConfig.api.url + "/api/stats/deliveriescount/10")
          .then(function (response) {
            return response.data;
          })
          .catch(function (err) {
            return err.data;
          });
      };

      this.fetchCompletedFinishedProductsCount = function (qte) {
        return $http
          .get(
            appConfig.api.url +
              "/api/stats/completedfinishedproductscount/" +
              qte
          )
          .then(function (response) {
            return response.data;
          })
          .catch(function (err) {
            return err.data;
          });
      };

      this.fetchCompletedFinishedProductsCountWeek = function (qte) {
        return $http
          .get(
            appConfig.api.url +
              "/api/stats/completedfinishedproductscountweek/" +
              qte
          )
          .then(function (response) {
            return response.data;
          })
          .catch(function (err) {
            return err.data;
          });
      };

      this.fetchInventoryDistribution = function () {
        return $http
          .get(appConfig.api.url + "/api/stats/inventorydistribution/")
          .then(function (response) {
            return response.data;
          })
          .catch(function (err) {
            return err.data;
          });
      };

      this.fetchDates = function () {
        return $http
          .get(appConfig.api.url + "/api/stats/dates/")
          .then(function (response) {
            return response.data;
          })
          .catch(function (err) {
            return err.data;
          });
      };

      /*****************************************/
      /*																			 */
      /*							Machines								 */
      /*																			 */
      /*****************************************/

      this.fetchMachine = function (machineId) {
        return $http
          .get(appConfig.api.url + "/api/machines/" + machineId)
          .then(function (response) {
            return response.data;
          });
      };

      this.fetchMachines = function (startDate, endDate) {
        var url = appConfig.api.url + "/api/machines";
        if (startDate || endDate) {
          url += "?";
        }
        if (startDate) {
          url += "startDate=" + startDate.toISOString() + "&";
        }
        if (endDate) {
          url += "endDate=" + endDate.toISOString();
        }
        return $http
          .get(url)
          .then((req) => req.data.map((o) => new Machine(o)));
      };

      this.createMachine = function (machine) {
        return $http
          .post(appConfig.api.url + "/api/machines", {
            number: machine.number,
            type: machine.type,
          })
          .then(function (response) {
            return response.data;
          });
      };

      this.updateMachine = function (machine) {
        return $http
          .put(appConfig.api.url + "/api/machines/" + machine._id, {
            number: machine.number,
            type: machine.type,
          })
          .then(function (response) {
            return response.data;
          });
      };

      /*****************************************/
      /*																			 */
      /*									PDF									 */
      /*																			 */
      /*****************************************/

      this.downloadPdfTable = function (tableStructure, pdfName) {
        return $http({
          url: appConfig.api.url + "/api/pdf/table",
          method: "POST",
          data: tableStructure,
          headers: {
            "Content-type": "application/json",
          },
          responseType: "arraybuffer",
        }).error(function (data, status, headers, config) {
          let blob = new Blob([data], { type: "application/pdf" });
          download(blob, pdfName + ".pdf");
        });
      };

      this.downloadDeliverySlipPdf = function (deliveryId) {
        return $http({
          url: appConfig.api.url + "/api/pdf/deliverySlip/" + deliveryId,
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
          responseType: "arraybuffer",
        }).error(function (data, status, headers, config) {
          let blob = new Blob([data], { type: "application/pdf" });
          download(blob, "deliverySlip.pdf");
        });
      };

      this.downloadSubdeliverySlipPdf = function (subdeliveryId, bol) {
        return $http({
          url:
            appConfig.api.url +
            "/api/pdf/subDeliverySlip/" +
            subdeliveryId +
            "?bol=" +
            bol,
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
          responseType: "arraybuffer",
        }).error(function (data, status, headers, config) {
          let blob = new Blob([data], { type: "application/pdf" });
          download(blob, "deliverySlip.pdf");
        });
      };

      this.downloadJobCreationPdf = function (subjob) {
        const id = subjob.id != null ? subjob.id : subjob._id;
        return $http({
          url: appConfig.api.url + "/api/pdf/jobCreation/" + id,
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
          responseType: "arraybuffer",
        }).error(function (data, status, headers, config) {
          let blob = new Blob([data], { type: "application/pdf" });
          download(blob, "job-creation-" + id + ".pdf");
        });
      };

      this.downloadMoldCreationPdf = function (item) {
        return $http({
          url: appConfig.api.url + "/api/pdf/moldCreation/" + item.id,
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
          responseType: "arraybuffer",
        }).error(function (data, status, headers, config) {
          let blob = new Blob([data], { type: "application/pdf" });
          download(blob, "item-creation-" + item.id + ".pdf");
        });
      };

      this.downloadSalesmenSchedulePdf = function (ids, machineType) {
        return $http({
          url:
            appConfig.api.url +
            "/api/pdf/salesmenSchedule?ids=" +
            ids.join("+") +
            "&machineType=" +
            machineType,
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
          responseType: "arraybuffer",
        }).error(function (data, status, _headers, _config) {
          if (status === 200) {
            let blob = new Blob([data], { type: "application/pdf" });
            download(blob, "salesmen-schedule.pdf");
          }
        });
      };

      this.downloadJobSchedulePdf = function (machineType) {
        return $http({
          url:
            appConfig.api.url +
            "/api/pdf/jobsSchedule?machineType=" +
            machineType,
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
          responseType: "arraybuffer",
        }).error(function (data, status, _headers, _config) {
          if (status === 200) {
            let blob = new Blob([data], { type: "application/pdf" });
            download(blob, "salesmen-schedule.pdf");
          }
        });
      };

      this.downloadMoldSchedulePdf = function (machineType) {
        return $http({
          url:
            appConfig.api.url +
            "/api/pdf/moldSchedule?machineType=" +
            machineType,
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
          responseType: "arraybuffer",
        }).error(function (data, status, _headers, _config) {
          if (status === 200) {
            let blob = new Blob([data], { type: "application/pdf" });
            download(blob, "mold-schedule.pdf");
          }
        });
      };

      this.downloadMechanicSchedulePdf = function (weekIndices, machineType) {
        return $http({
          url:
            appConfig.api.url +
            "/api/pdf/mechanicSchedule?ids=" +
            weekIndices.join("+") +
            "&machineType=" +
            machineType,
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
          responseType: "arraybuffer",
        }).error(function (data, status, _headers, _config) {
          if (status === 200) {
            let blob = new Blob([data], { type: "application/pdf" });
            download(blob, "mechanic-schedule.pdf");
          }
        });
      };

      this.downloadProductionReportPdf = function (weekIndices, machineType) {
        return $http({
          url: appConfig.api.url + "/api/pdf/productionReport",
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
          responseType: "arraybuffer",
        }).error(function (data, status, _headers, _config) {
          if (status === 200) {
            let blob = new Blob([data], { type: "application/pdf" });
            download(blob, "production-report.pdf");
          }
        });
      };
    }
  );

  const download = function (data, filename) {
    var file = new Blob([data], { type: "application/pdf" });
    if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }